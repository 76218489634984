<template>
    <Modal 
        @close="$emit('close')"
        insideClasses="bg-white"
        width="auto">
        
        <header slot="header">
            Invitér nye brukere
        </header>
        
        <div class="overflow-y-auto max-h-screen">
            <table v-if="invitations && invitations.length">
                <thead class="bg-beige-light">
                    <tr>
                        <th></th>
                        <th>E-postadresse</th>
                        <th>Fornavn</th>
                        <th>Etternavn</th>
                        <th>Telefonnummer</th>
                        <th>Brukerrettigheter</th>
                        <th>Send e-post</th>
                        <th>Send SMS</th>
                        <th>Passord</th>
                        <!-- <th class="pr-8">Response</th> -->
                        <th class="pr-8">Status</th>
                        <!-- <th>Invitér</th> -->
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="invitation in invitations"
                        :key="'invitationIndex_'+invitation.invitationId">
                        
                        <td 
                            class="pl-8"
                            :class="invitation.response && invitation.response.user ? 'cursor-not-allowed' : null">
                            
                            <input 
                                type="checkbox" 
                                v-model="invitation.include"
                                style="margin-top: 1rem;"
                                :class="invitation.response && invitation.response.user ? 'opacity-25 pointer-events-none' : null"
                            />
                        </td>
                        <td>
                            <InputSingleline 
                                v-model="invitation.email"
                                type="email"
                                :required="true"
                            />
                        </td>
                        <td>
                            <input 
                                v-model="invitation.given_name"
                                type="text"
                                :required="true"
                                class="w-32"
                            />
                        </td>
                        <td>
                            <input 
                                v-model="invitation.family_name"
                                type="text"
                                :required="true"
                                class="w-32"
                            />
                        </td>
                        <td>
                            <input 
                                v-model="invitation.phone_number"
                                type="text"
                                :required="false"
                                class="w-32"
								placeholder="+47xxxxxxxx"
                            />
                        </td>
                        <td>
                            <select v-model="invitation.role">
                                <option value="admin">Administrator</option>
                                <option value="regular">Normal</option>
                            </select>
                            
                            <div v-if="invitation.role != 'admin'">
                                <label for="">Delta i avdeling(er)</label>
                                <div v-if="departments && departments.length">
                                    <div 
                                        v-for="department in departments"
                                        :key="'department_'+department.departmentId"
                                        @click="onToggleDepartment(invitation, department.departmentId)"
                                        class="cursor-default flex my-4 items-start">
                                        
                                        <input 
                                            type="checkbox" 
                                            :checked="invitation.departments.find( uD => uD == department.departmentId)"
                                        />
                                        {{department.name}}
                                    </div>
                                </div>
                                <em v-else>
                                    Ingen avdelinger funnet ennå.
                                </em>
                            </div>
                        </td>
                        <td>
                            <input 
                                type="checkbox" 
                                v-model="invitation.sendEmail"
                                style="margin-top: 1rem;"
                            />
                        </td>
                        
                        <td>
                            <input 
                                type="checkbox" 
                                v-model="invitation.sendSms"
                                style="margin-top: 1rem;"
                            />
                        </td>
						
                        <td>
                            <label 
                                :for="'setCustomPassword_'+invitation.invitationId+'_false'"
                                style="margin-top: 0;">
                                
                                <input 
                                    type="radio" 
                                    :id="'setCustomPassword_'+invitation.invitationId+'_false'"
                                    v-model="invitation.setCustomPassword"
                                    :value="false"
                                    class="mr-4"
                                    @change="invitation.temporaryPassword = null"
                                />
                                
                                Lag engangspassord automatisk
                            </label>
                            
                            <label 
                                :for="'setCustomPassword_'+invitation.invitationId+'_true'"
                                class="pt-1"
                                style="margin-top: 0;">
                                
                                <input 
                                    type="radio" 
                                    :id="'setCustomPassword_'+invitation.invitationId+'_true'"
                                    v-model="invitation.setCustomPassword"
                                    :value="true"
                                    class="mr-4"
                                    @change="invitation.temporaryPassword = null"
                                />
                                
                                Jeg vil definere engangspassord 
                            </label>
                            
                            <div 
                                v-if="invitation.setCustomPassword"
                                class="mt-3 bg-yellow-300">
                                
                                <input 
                                    v-model="invitation.temporaryPassword"
                                    type="text"
                                    :required="true"
                                    class="w-full"
                                />
                            </div>
                        </td>
                        
                        <!-- <td>
                            <div 
                                v-if="invitation.response"
                                class="h-100 overflow-scroll">
                                <pre>{{invitation.response}}</pre>
                            </div>
                        </td> -->
                        
                        <td :class="invitation.error ? 'bg-red-600 text-white' : ''">
                            <div v-if="invitation.error">
                                {{invitation.error}}
                                <!-- <pre>{{invitation.error}}</pre> -->
                            </div>
                            <div v-else-if="invitation.status">
                                <div v-if="invitation.status == 'userInvited'">
                                    <div v-if="invitation.sendEmail">
                                        Invitasjon sendt på epost inkludert engangspassord
                                    </div>
                                    <div v-else>
                                        Bruker ble invitert uten epost-varsling.
                                        <div v-if="invitation.response.temporaryPassword">
                                            Midlertidig engangspassord:
                                            <div class="text-2xl text-center bg-gray-dark text-white px-1 mt-1" style="font-family: mono">
                                                {{invitation.response.temporaryPassword}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="invitation.status == 'userAlreadyExist'">
                                    Eksisterende bruker ble invitert til å delta i denne Upsell-kontoen.
                                </div>
                                <!-- {{invitation.status}} -->
                            </div>
                        </td>
                        
                        <!-- <td>
                            <button 
                                type="submit"
                                @click.prevent="onUserInvite( invitation )" 
                                :disabled="loading"
                                class="button submit"
                                :class="{'loading' : loading == 'inviting'}">
                                
                                Invitér
                            </button>
                        </td> -->
                        
                        <td class="pr-4">
                            <button 
                                @click="onRemoveInvitation( invitation )"
                                class="button">
                                
                                Fjern
                            </button>
                            <!-- <div class="flex-none text-xl text-beige-darker w-8 h-8 ml-4 mt-3">
                                <i 
                                    class="fas fa-times "
                                    @click="onRemoveInvitation( invitation )"
                                />
                            </div> -->
                            <!-- <pre>{{invitation.invitationId}}</pre> -->
                        </td>
                    </tr>
                </tbody>
            </table>
            <div 
                v-else
                class="p-6">
                
                <div class="max-w-sm">
                    <h2>Klipp &amp; Lim</h2>
                    <p>
                        For å importere fra Excel kan du markére rader- og kolonneinformasjon, og lime direkte inn her.
                        Under kolonnen "Departments" kan du definére avdelinger ved å bruke navn på avdelingene registrert i Upsell.
                    </p>
                    
                    <a 
                        href="/assets/template_import_upsell_2023.xlsx" 
                        class="button w-full">
                        
                        Last ned Excel-mal
                    </a>
                </div>
            </div>
        </div>
        
        <footer slot="footer">
            <div class="space-x-4 flex mt-6">
                <div class="flex-grow">
                    <a 
                        class="button mr-2" 
                        @click="onAddInvitation">
                        
                        Legg til rad
                    </a>
                    
                    <!-- 
                    
                    <a 
                        class="button" 
                        @click="onTempData">
                        
                        onTempData
                    </a> -->
                </div>
                
                <a 
                    class="button" 
                    @click="$emit('close')">
                    
                    Avbryt
                </a>
                
                <button 
                    type="submit"
                    @click.prevent="onUsersBatchInvite()" 
                    :disabled="loading || !invitations.find(i => i.include)"
                    class="button submit"
                    :class="{'loading' : loading == 'batchInviting'}">
                    
                    Invitér markerte
                </button>
            </div>
        </footer>
    </Modal>
</template>



<script>
export default {
    computed:{
        departments(){
            return this.$store.getters.getDepartments;
        },
    },
    
    data() {
        return {
            loading: null,
            invitations: []
        }
    },
    
    methods: {
        onAddInvitation( invitation = {} ){
            this.invitations.push({
                include: true,
                invitationId: this.generateUID(),
                role: 'admin',
                email: null,
                locale: 'nb',
                departments: [],
                given_name: null,
                family_name: null,
				phone_number: null,
                sendEmail: true,
                sendSms: false,
                setCustomPassword: false,
                temporaryPassword: null,
                status: null,
                error: null,
                response: null,
                ...invitation,
            });
        },
        
        onRemoveInvitation( invitation ){
            this.invitations = this.invitations.filter( i => i != invitation);
        },
        
        async onUsersBatchInvite(){
            this.loading = 'batchInviting';
            
            const invitationPromises = [];
            
            this.invitations.forEach( invitation => {
                if ( invitation.include ) {
                    invitationPromises.push( this.onUserInvite( invitation ) );
                }
            });
            
            const invitationPromisesResponse = await Promise.allSettled( invitationPromises );
            console.log('invitationPromisesResponse', invitationPromisesResponse);
            
            await this.$store.dispatch('listUsers');
            this.loading = null;
        },
        
        async onUserInvite( invitation ){
            try {
                invitation.error = null;
                invitation.status = null;
                
                // if ( 
                //     invitation.setCustomPassword &&
                //     invitation.password.includes("\\") ) {
                // 
                //     throw Error( 'Feil i passord!' );
                // }
                // 
                // return
                
                if (
                    invitation.email == '' || 
                    !this.emailIsValid(invitation.email) ) {
                    
                    throw Error( 'For å invitere kreves en gyldig e-postadresse' );
                }
                
                if (
                    !invitation.family_name || 
                    invitation.family_name == '' || 
                    !invitation.given_name || 
                    invitation.given_name == '' ) {
                    
                    throw Error( 'En bruker må ha fornavn og etternavn' );
                }
				
                // this.loading = 'inviting';
                
                let invitationResponse = await this.accountUsersInvite( {
                    invitation,
                    account: this.account,
                });
                
                console.log('invitationResponse', invitation, invitationResponse);
                
                invitation.response = invitationResponse;
                
                if (invitationResponse.createOrUpdate) {
                    invitation.include = false;
                    
                    if (invitationResponse.createOrUpdate == 'create') {
                        invitation.status = 'userInvited';
                    }
                    else if (invitationResponse.createOrUpdate == 'update') {
                        invitation.status = 'userAlreadyExist';
                    }
                }
            } 
            catch (error) {
                console.log(error);
                // this.$notify( {type: 'error', text: error.message} );
                invitation.error = error.message || error;
                invitation.status = 'userInviteError';
            }
            finally{
                // this.loading = null;
            }
        },
        
        onToggleDepartment( invitation, departmentId ){
            if ( invitation.departments.find( uD => uD == departmentId) ) {
                invitation.departments = invitation.departments.filter( uD => uD != departmentId );
            }
            else {
                invitation.departments.push( departmentId );
            }
        },
        
        emailIsValid(email) {
            return /\S+@\S+\.\S+/.test(email)
        },
        
        onPaste( event ){
            try {
                let clipText = (event.clipboardData || window.clipboardData).getData('text');
                
                if ( 
                    clipText.includes('\n') ||
                    clipText.includes('\r') ||
                    clipText.includes('\t') ) {
                    
                    // console.log('table data pasted');
                    event.preventDefault();
                }
                else {
                    // console.log('regular data pasted');
                    return;
                }
                
                let clipRows = clipText.split('\n');
                
                clipRows.forEach( rowRaw => {
                    const cols = rowRaw.split('\t');
					console.log('cols', cols);
					
                    
                    if ( !this.emailIsValid( cols[0] ) ) {
                        console.log('cols not email',cols);
                    }
                    else {
                        let departments = [];
                        
                        if (
                            (cols[4] != 'x' || cols[4] != 'X') && // check if not set to Admin role
                            cols[5] != '' && cols[5].length > 2 ) {
                            
                            const departmentNames = cols[5]
                                    .split(',')
                                    .map( name => {
                                        return name.trim();
                                    })
                                    .map( name => {
                                        return name.toLowerCase();
                                    });
                                    
                            // departments = departmentNames;
                            
                            if ( departmentNames.length && this.departments) {
                                departmentNames.forEach( name => {
                                    const department = this.departments.find( uD => uD.name.toLowerCase() == name);
                                    
                                    if ( department ) {
                                        departments.push( department.departmentId );
                                    }
                                });
                            }
                        }
                        
                        
                        let temporaryPassword = cols[8] && cols[8] != '' && cols[8].length > 2 ? cols[8] : null;
                        
                        if (temporaryPassword) {
                            temporaryPassword = temporaryPassword.replace( /[\\]+/gm, '');
                        }
                        
						
                        let phone_number = cols[3];
						
                        if (phone_number && phone_number.length && phone_number.startsWith('00') ) {
                            phone_number = '+' + phone_number.slice(2);
                        }
                        
                        const invitation = {
                            email: cols[0],
                            given_name: cols[1] || '',
                            family_name: cols[2] || '',
                            phone_number, //: cols[3] || '',
                            role: cols[4] && cols[4] == 'x' || cols[4] == 'X' ? 'admin' : 'regular',
                            departments,
                            sendEmail: cols[6] && cols[6] == 'x' || cols[6] == 'X',
                            sendSms: cols[7] && cols[7] == 'x' || cols[7] == 'X',
                            setCustomPassword: cols[8] && cols[8] != '' && cols[8].length > 2 ? true : false,
                            temporaryPassword,
                            locale: 'nb',
                        };
                        
                        console.log('invitation', invitation);
                        
                        this.onAddInvitation( invitation );
                    }
                });
            } 
            catch (e) {
                console.log('Pasted from clipboard is not table data');
                console.log(e);
            }
        }
    },
    
    async mounted(){
        // await this.$store.dispatch('getUser', this.signedInUserPassed.username);
        window.addEventListener('paste', this.onPaste);
    },
    
    beforeDestroy() {
        window.removeEventListener('paste', this.onPaste);
    },
}
</script>

<style lang="scss">
</style>